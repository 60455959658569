<template>
    <modal ref="modalVerImagenUnica" titulo="" tamano="modal-lg">
        <div class="container">
            <div class="row justify-content-center">
                <div class="col-10">
                    <img src="/img/pruebas/anuncio1.png" alt="" class="w-100">
                </div>
            </div>
        </div>
    </modal>
</template>
<script>
export default {
    data(){
        return{
            inputComentario:'',
        }
    },
    methods:{
        toggle(){
            this.$refs.modalVerImagenUnica.toggle()
        },
        guardarValores(){
            this.$refs.modalVerImagenUnica.toggle()
        }
    }
}
</script>
<style lang="scss" scoped>

</style>